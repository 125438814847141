<template>
<div>
    <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>
    <!-- Login {{line_user_id}} -->
</div>
</template>

<script>
import {
    APIURL
} from '@/constants'
import { LIFFID } from '@/constants'
import Vue from 'vue'
import WaitingModal from "@/components/WaitingModal.vue";
export default {
    name: "login",
    components: {
        WaitingModal,
    },
    data() {
        return {
            active_waiting: false,
            title: '',
            status: '',
            lineid: '',
            browser: '',
            is_line_login: false,
            is_checkmong_register: false,
            user_checkmong: {}
        };
    },


    // comment this on dev
    async beforeCreate() {
        await window.liff.init({
            liffId: LIFFID,
        })
        if (window.liff.isInClient()) {
            this.getProfileLine();
            this.browser = 'LIFF browser';
            this.$store.commit('setBrowser','line')
            this.is_line_login = true;
        } else {
            this.browser = 'External browser';
            this.$store.commit('setBrowser','browser')
            if (window.liff.isLoggedIn()) {
                //console.log('back to checkmong')
                this.getProfileLine();
                this.is_line_login = true;
            } else {
                //console.log("please Login")
                window.liff.login()
            }
        }
    },


    
    async mounted() {
        // this.$store.commit('clearStore','')
        // localStorage.removeItem('store')
        /////////////////////////// for developing comment on prd
        // this.lineid = 'U7f7efc1dfa8533f396b4260e2a9e7445' // ผู้ป่วย 
        // this.lineid = 'U395fa0b11bb5fb64bf3a15d3d62b8c4c' // อสม 
        // this.lineid = 'Ud425eda78da00f6433a351c6df9ec49f' // รพ คลินิก
        // this.lineid = 'Ub14452d6e900604ea8594a6f8aa771e2' // Chad // user_id: "2000087"
        // this.getUser() 


        if (window.liff.isInClient()){
            this.$store.commit('setBrowser','line')
        }else {
            this.$store.commit('setBrowser','browser')
        }
    },

    watch: {

    },
    updated() {
        // // comment on dev
        // //console.log('updated')
        this.getProfileLine()
        
    },


    methods: {
            update_status_wait(val) {
            this.active_waiting = val
        },
        async getProfileLine() {
            await window.liff.getProfile().then((profile) => {
                this.lineid = profile.userId;
                this.getUser()
            })
            // await 
        },
        async getUserInfo(){
                //console.log('this.$store.state.login_detail.user_id:',this.$store.state.login_detail.user_id)
                await Vue.axios.post(APIURL + '/get_user_info', {
                    "user_id": this.$store.state.login_detail.user_id
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    //console.log('get_user_info',response)
                    if (response.data) {
                        //console.log('get_user_info',response.data)
                        this.active_waiting = false
                        this.$store.commit('setUserInfo',response.data);
                        // this.province = response.data.province
                        // this.district = response.data.district
                        // this.subdistrict_list = response.data.location_list
                        // this.location_id = response.data.location_list
                        this.$router.push('/home_checkmong')
                    } else {
                        this.active_waiting = false

                    }

                })

        },
        async getUser() {
            //console.log('this.lineid---->',this.lineid)
            this.active_waiting = true
                this.title = 'กำลังเข้าสู่ระบบ',
                    this.status = 'loading'
            const data = {
                "lineid": String(this.lineid)
            }
            var res = {}
            await
            Vue.axios.post(APIURL + '/get_user', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                this.active_waiting = false
                res = response.data
                //console.log('get_user:',response.data)
            })

            if (res.user_id !== '') {
                this.$store.commit('setLoginDetail', {
                    user_id: res.user_id,
                    user_type: res.user_type,
                    user_token: res.user_token,

                });
                this.getUserInfo()

                
                // //console.log('login---')
                // //console.log(this.$store.state)
            } else {
                this.$store.commit('setRegisterForm', {
                    lineid: this.lineid
                });
                this.$router.push('/register_select_type')
            }
        },

        goBack() {
            this.$router.push({
                path: '/pdpa',
                replace: true
            })
        }
    },
};
</script>

<style>

</style>
